import { Routes } from '@angular/router';

export const getErrorRoutes: Routes = [
  {
    path: 'errors',
    children: [
      {
        path: 'page-not-found',
        loadComponent: () =>
          import('./error-page.component').then(
            ({ ErrorPageComponent }) => ErrorPageComponent
          ),
        data: {
          code: 404,
          message: 'La page que vous recherchez est introuvable.',
        },
      },
      {
        path: 'unauthorized',
        loadComponent: () =>
          import('./error-page.component').then(
            ({ ErrorPageComponent }) => ErrorPageComponent
          ),
        data: {
          code: 403,
          message: 'Forbidden',
        },
      },
      {
        path: 'crash',
        loadComponent: () =>
          import('./error-page.component').then(
            ({ ErrorPageComponent }) => ErrorPageComponent
          ),
        data: {
          code: 500,
          message:
            'Une erreur est survenue. Merci de bien vouloir réessayer plus tard.',
        },
      },
      {
        path: 'unavailable',
        loadComponent: () =>
          import('./error-page.component').then(
            ({ ErrorPageComponent }) => ErrorPageComponent
          ),
        data: {
          code: 500,
          message:
            'Une erreur est survenue. Merci de bien vouloir réessayer plus tard.',
        },
      },
    ],
  },
];
