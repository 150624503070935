<footer
  class="mlk-bg-light-blue-gradient d-flex flex-column flex-md-row flex-wrap mlk-text-black gap-3 gap-lg-2 justify-content-center align-items-center py-5">
  <span
    >&copy; {{ currentDate | date: 'yyyy' }} Eglise MLK - Tous droits
    réservés</span
  >
  <a class="nav-link px-2" routerLink="/mentions-legales">Mentions Légales</a>
  <!--    <a href="#" class="nav-link px-2 text-muted">Conditions Générales D'Utilisations</a>-->
  <a class="nav-link px-2" routerLink="/privacy-policy">Données Personnelles</a>
  <a
    class="nav-link px-2"
    href="https://docs.google.com/forms/d/e/1FAIpQLSdkqPqa9zb4ISQVoEeUGGiBW1-jmsVUnxOwYvNLhxqknwlmpA/viewform?vc=0&c=0&w=1&flr=0"
    target="_blank"
    >Signaler un problème</a
  >
</footer>
