import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authenticationGuard } from '@shared/guards';
import { getErrorRoutes } from './pages/error-page/error.routing';

export const appRoutes: Routes = [
  {
    path: 'mlk',
    children: [
      {
        path: 'donation',
        loadChildren: () => import('./pages/donation/donation.routing'),
      },
      {
        path: '',
        canActivate: [authenticationGuard()],
        children: [
          {
            path: 'notifications',
            loadComponent: () =>
              import('./pages/notifications/notifications.component').then(
                ({ NotificationsComponent }) => NotificationsComponent
              ),
          },

          {
            path: 'home',
            loadComponent: () =>
              import(
                './pages/home/components/private-home/private-home.component'
              ).then(({ PrivateHomeComponent }) => PrivateHomeComponent),
          },
          {
            path: 'embassies',
            loadChildren: () => import('./pages/embassy/embassy.routing'),
          },
          {
            path: 'profile',
            loadChildren: () => import('./pages/profile/profile.routing'),
            //            loadComponent: () =>
            //              import('./pages/profile/profile.component').then(
            //                ({ ProfileComponent }) => ProfileComponent
            //              ),
          },
          {
            path: 'event',
            loadChildren: () => import('./pages/events/events.routing'),
          },
        ],
      },
    ],
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.component').then(
        ({ LoginComponent }) => LoginComponent
      ),
  },
  {
    path: 'register',
    loadComponent: () =>
      import('./pages/register/register.component').then(
        ({ RegisterComponent }) => RegisterComponent
      ),
  },
  {
    path: 'donation',
    loadChildren: () => import('./pages/donation/donation.routing'),
  },
  {
    path: 'embassies',
    loadComponent: () =>
      import(
        './pages/embassies-search-engine/embassies-search-engine.component'
      ).then(
        ({ EmbassiesSearchEngineComponent }) => EmbassiesSearchEngineComponent
      ),
  },
  {
    path: 'reset-password',
    loadComponent: () =>
      import('./pages/reset-password/reset-password.component').then(
        ({ ResetPasswordComponent }) => ResetPasswordComponent
      ),
  },
  {
    path: 'mentions-legales',
    loadComponent: () =>
      import('./pages/page-mention-legale/page-mention-legale.component').then(
        ({ PageMentionLegaleComponent }) => PageMentionLegaleComponent
      ),
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./pages/page-rgpd/page-rgpd.component').then(
        ({ PageRGPDComponent }) => PageRGPDComponent
      ),
  },
  {
    path: 'activate',
    loadComponent: () =>
      import('./pages/activated-account/activated-account.component').then(
        ({ ActivatedAccountComponent }) => ActivatedAccountComponent
      ),
  },
  {
    path: '',
    loadComponent: () =>
      import('./pages/home/components/public-home/public-home.component').then(
        ({ PublicHomeComponent }) => PublicHomeComponent
      ),
  },
  ...getErrorRoutes,
  {
    path: '**',
    redirectTo: 'errors/page-not-found',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
