import { Injectable, inject } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { AuthenticationService } from '@shared/services';

@Injectable()
export class HttpBackendInterceptor implements HttpInterceptor {
  /** Injection of {@link AuthenticationService}. */
  private readonly authService = inject(AuthenticationService);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      req.url.endsWith('/token/') ||
      req.url.endsWith('/reset-password-request/') ||
      req.url.endsWith('/verify-email-token/') ||
      req.url.endsWith('/verify-reset-password-token/') ||
      (req.url.endsWith('/users/') && req.method === 'POST') ||
      (req.url.endsWith('/v1/embassies/') && req.method === 'GET') ||
      (req.url.endsWith('/v1/events/**') && req.method === 'GET') ||
      req.url.startsWith('https://maps.google.com/maps/api/geocode') ||
      req.url.includes('.eglisemlk.fr/donation-campaign/') ||
      req.url.includes('.eglisemlk.fr/stripe/checkout-session/') ||
      req.url.includes('.eglisemlk.fr/checkout-session/')
    ) {
      return next.handle(req);
    }

    if (!this.authService.hasValidAccessToken()) {
      return this.authService.refreshToken().pipe(
        switchMap(() => {
          const headers = new HttpHeaders().append(
            'Authorization',
            `Bearer ${this.authService.access_token}`
          );
          const modifiedReq = req.clone({ headers: headers, url: req.url });
          return next.handle(modifiedReq);
        })
      );
    }

    const headers = new HttpHeaders().append(
      'Authorization',
      `Bearer ${this.authService.access_token}`
    );
    const modifiedReq = req.clone({ headers: headers, url: req.url });
    return next.handle(modifiedReq);
  }
}
