import { inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, EMPTY, Observable, retry, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '@shared/services/authentication/authentication.service';
import { ToastService } from '../services/toast/toast.service';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  /** Injection of {@link Router}. */
  private readonly router = inject(Router);

  /** Injection of {@link ToastService}. */
  private readonly toastService = inject(ToastService);

  /** Injection of {@link AuthenticationService}. */
  private readonly authService = inject(AuthenticationService);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retry(0),

      catchError(err => this.handleErrors(err))
    );
  }

  private handleErrors(err: HttpErrorResponse) {
    const isFormFieldErrors =
      err.status === 400 && !('non_field_errors' in err.error);

    const isNotFormFieldErrors =
      err.status === 400 && 'non_field_errors' in err.error;

    if (isFormFieldErrors || err.status === 422) {
      return throwError(() => err);
    } else if (isNotFormFieldErrors) {
      this.toastService.showDanger(err.error['non_field_errors'][0]);
      return EMPTY;
    } else if (err.status === 503) {
      this.router.navigate(['/errors/unavailable']);
      return EMPTY;
    } else if (err.status === 401 && this.router.url !== '/login') {
      this.notifyErrorUnauthorized(err);
      return EMPTY;
    } else if (err.status === 404) {
      this.router.navigate(['/errors/page-not-found']);
      return EMPTY;
    } else {
      return throwError(() => err);
    }
  }

  private notifyErrorUnauthorized(serverError: HttpErrorResponse) {
    if (this.router.url === '/login' && serverError?.error.detail) {
      this.toastService.showDanger(
        serverError.error.detail,
        'An error occured'
      );
      return null;
    } else {
      this.authService.logout();
      return this.router.navigate(['/login']);
    }
  }
}
