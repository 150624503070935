<div>
  <mlk-toasts-container aria-live="polite" aria-atomic="true" />
  <mlk-menu />
  <div class="mlk-app-wrapper">
    <main role="main" class="mlk-app-content">
      <router-outlet></router-outlet>
    </main>
  </div>
  <mlk-footer />
</div>
